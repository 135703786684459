import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from '../../layouts/toolboxLayout';
import { getUser } from 'services/userService';
import AlertSettingsContent from 'components/Page/Toolbox/AlertSettingsContent';
import userToolBoxJwt from 'hooks/useToolboxStorage';

const AlertSettings = (props) => {
  const [userInfo, setUserInfo] = useState(null);
  const [isWaiting, setIsWaiting] = useState(true);
  const { seo: seoContent } = props.data.contentfulContentHub;

  useEffect(() => {
    if (!userInfo) {
      const jwt = userToolBoxJwt.get();
      if (!jwt) {
        navigate('./toolbox');
      }
      getUser(jwt)
        .then((data) => {
          setUserInfo(data);
          setIsWaiting(false);
        })
        .catch(() => {
          setUserInfo(null);
          userToolBoxJwt.remove();
        });
    }
  }, []);

  return (
    <ToolboxLayout contentfulSeo={seoContent} footer={null} header={null} layout="toolbox">
      {userInfo && (
        <AlertSettingsContent isWaiting={isWaiting} userInfo={userInfo} onSave={() => {}} />
      )}
    </ToolboxLayout>
  );
};

export default AlertSettings;

export const AlertSettingsPage = graphql`
  query AlertSettingsPageQuery {
    contentfulContentHub(internalName: { eq: "Toolbox" }) {
      seo {
        ...Seo
      }
    }
  }
`;
