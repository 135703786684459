import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';

export const AlertSettingsSectionLayout = styled(SectionLayout)`
  background-color: rgba(25, 25, 25, 0.9);
  color: var(--white);
  .section-layout--inner-container {
    padding-top: 100px;
  }

  min-height: 620px;

  .waiting-container {
    height: 100%;
  }

  @media (max-width: 1199px) {
    .section-layout--inner-container {
      padding-top: 60px;
    }
  }

  @media (max-width: 767px) {
    .section-layout--inner-container {
      padding-top: 50px;
    }
  }

  @media (max-width: 575px) {
    .section-layout--inner-container {
      padding: 50px 20px 55px;
    }
  }
`;
